import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'

// const API_URL = process.env.API_URL || 'https://store.connectedh.com/'

        var vueax = axios.create({
            // baseURL: 'http://localhost:8500/',
            headers: {
                'Content-Type': 'application/json'
            }
        })

Vue.use(VueAxios, vueax)
export default vueax
