import {axios} from '@/plugins/axios.js'

const getConfig = (path) => new Promise((resolve, reject) => {
    axios.get('/website-api/config',{withCredentials: true, params: {path: path}})
    .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
});

const fetchTests = (limit,offset) => new Promise((resolve, reject) => {
    axios.get('/website-api/tests',{withCredentials: true, params: {limit:limit,offset:offset}})
    .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
});

const fetchPackages = (limit,offset) => new Promise((resolve, reject) => {
    axios.get('/website-api/packages',{withCredentials: true, params: {limit:limit,offset:offset}})
    .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
});

const getRadiologyTests = (filters,limit,offset) => new Promise((resolve, reject) => {
    axios.get('/website-api/radiology-tests',{withCredentials: true, params: {filters:filters,limit:limit,offset:offset}})
    .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
});

const getProducts = () => new Promise((resolve, reject) => {
    axios.get('/website-api/products',{withCredentials: true})
    .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
});

const getCities = () => new Promise((resolve, reject) => {
    axios.get('/website-api/get-cities',{withCredentials: true})
    .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
});

const getSearchProducts = () => new Promise((resolve, reject) => {
    axios.get('/website-api/get-products',{withCredentials: true})
    .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
});

const getFilteredTests = (filters,limit,offset) => new Promise((resolve, reject) => {
    axios.get('/website-api/category-wise-tests',{withCredentials: true, params: {filters:filters,limit:limit,offset:offset}})
    .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
});

const getCentersInCity = (city) => new Promise((resolve, reject) => {
    axios.get('/website-api/get-centers-in-city',{withCredentials: true, params: {city}})
    .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
});

const getTestById = (id) => new Promise((resolve, reject) => {
    axios.get('/website-api/get-test-by-id',{withCredentials: true, params: {id}})
    .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
});

const getTestByName = (name) => new Promise((resolve, reject) => {
    axios.get('/website-api/get-test-by-name',{withCredentials: true, params: {name}})
    .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
});

const getPackageById = (id) => new Promise((resolve, reject) => {
    axios.get('/website-api/get-package-by-id',{withCredentials: true, params: {id}})
    .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
});

export default {
    getConfig,
    fetchTests,
    fetchPackages,
    getRadiologyTests,
    getProducts,
    getCities,
    getSearchProducts,
    getFilteredTests,
    getCentersInCity,
    getTestById,
    getTestByName,
    getPackageById
}
