import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from './axios'
import VueLodash from 'vue-lodash'
// import Vuetify from 'vuetify'
// import store from './store'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueGeolocation from 'vue-browser-geolocation'
// import BootstrapVue from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import 'vuetify/dist/vuetify.min.css'

// Vue.use(BootstrapVue)
// Vue.use(Vuetify)
const options = { name: 'lodash' }
Vue.use(VueLodash, options)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyARXcKKFblz_nDQJ8xJj9RdbjPICO0RhV0',
    libraries: 'places'
  },
})
Vue.use(VueGeolocation,{
	load: {
    key: 'AIzaSyARXcKKFblz_nDQJ8xJj9RdbjPICO0RhV0',
    libraries: 'places'
  },
})
Vue.config.productionTip = false

new Vue({
  router,
  axios,
  // store,
  render: h => h(App)
}).$mount('#app')
