<template>
	<div v-if="websiteConfig">
		<component v-bind:is="websiteConfig.theme" :config="websiteConfig"></component>
	</div>
</template>

<script>

import configService from '@/services/ConfigService.js'

export default {
	name: 'home',
	components: {
		theme4: () => import('@/components/themes/theme4/Main.vue'),
		theme5: () => import('@/components/themes/theme5/Main.vue'),
		theme6: () => import('@/components/themes/theme6/Main.vue'),
	    theme7: () => import('@/components/themes/theme7/Main.vue'),
	    theme8: () => import('@/components/themes/theme8/Main.vue'),
		theme9: () => import('@/components/themes/theme9/Main.vue'),
		theme10: () => import('@/components/themes/theme10/Main.vue'),
		theme11: () => import('@/components/themes/theme11/Main.vue')
	},
	data () {
		return {
			websiteConfig: null
		}
	},
	mounted() {
		if (window.websiteConfig) {
			this.websiteConfig = window.websiteConfig
		} else {
			configService.getConfig(this.$router.currentRoute.path)
			.then(resp => {
				this.websiteConfig = resp
			})
			.catch(err => {
			})
		}
	}
}
</script>
